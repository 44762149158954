import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { useMedia, useMeasure } from '../components/gallery';
import { graphql, navigate } from 'gatsby';
import { CategoryFilters } from '../components/gallery/categoryFilters';
import '../components/gallery/index.scss';
import { easeSinOut } from 'd3-ease';
import { makeMobileImage } from './ProjectDetail';
import { Helmet } from 'react-helmet';

function Gallery({ pageContext: { locale, categories }, data, ...props }: any) {
  // Hook2: Measure the width of the container element
  const [bind, { width }] = useMeasure();

  // Hook1: Tie media queries to the number of columns
  const columns =
    typeof window !== 'undefined' &&
    useMedia(
      ['(min-width: 1500px)', '(min-width: 1024px)', '(min-width: 600px)'],
      [5, 4, 2],
      1
    );

  let height =
    typeof window !== 'undefined'
      ? ((window.innerWidth * 0.75) /
          (window.innerWidth > 1024 ? 5 : window.innerWidth > 600 ? 2 : 1)) *
        2
      : 400;
  const media = data.allContentfulGallery.nodes[0].projectsList.map(
    ({
      title,
      slug,
      galleryImage,
      thumbnailMobile,
      category,
      galleryImageRatio,
    }: any) => {
      return {
        title,
        slug,
        css: makeMobileImage(
          galleryImage?.file?.url,
          thumbnailMobile?.file?.url
        ),
        height,
        category: category.map((el: any) => el.categoryName),
      };
    }
  );

  // Hook3: Hold items
  const [items, set] = useState(media);

  // Form a grid of stacked items using width & columns we got from hooks 1 & 2
  const heights = new Array(columns).fill(0); // Each column gets a height starting with zero
  const gridItems = items.map((child, i) => {
    const column = heights.indexOf(Math.min(...heights)); // Basic masonry-grid placing, puts tile into the smallest column using Math.min
    const xy = [
      (width / columns) * column,
      (heights[column] += width / columns) - width / columns,
    ]; // X = container width / number of columns * column index, Y = it's just the height of the current column

    return { ...child, xy, width: width / columns, height: width / columns };
  });

  // Hook5: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(gridItems, (item) => item.css, {
    from: ({ xy, width, height }) => ({ xy, width, height, opacity: 0 }),
    enter: ({ xy, width, height }) => ({ xy, width, height, opacity: 1 }),
    update: ({ xy, width, height }) => ({ xy, width, height }),
    leave: { height: 0, width: 0, opacity: 0 },
    config: {
      easing: easeSinOut,
      duration: 100,
    },
    trail: 0,
  });

  // Render the grid
  return (
    <>
      <Helmet>
        <meta name="title" content="Edge Fx - Gallery" />
        <meta
          name="description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://edgefx.tv/" />
        <meta property="og:title" content="Edge Fx - Gallery" />
        <meta
          property="og:description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta
          property="og:image"
          content="https://edgefx.tv/EdgeFxPreview.jpg"
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="300" />
      </Helmet>
      <div
        className={'chevron-control'}
        onClick={() => {
          navigate(`/${locale === 'it' ? '' : `${locale}`}#gallery`);
        }}
      >
        <svg
          enableBackground="new 0 0 515.556 515.556"
          height="24"
          viewBox="0 0 515.556 515.556"
          width="24"
        >
          <path
            stroke="#fff"
            fill="#fff"
            d="m128.885 257.778 257.778-257.778v128.886l-128.889 128.892 128.897 128.886-.008 128.892z"
          />
        </svg>
      </div>
      <div className={'pattern'}>
        <CategoryFilters categories={categories} media={media} setMedia={set} />
        <div
          {...bind}
          className="list"
          style={{ height: Math.max(...heights) }}
        >
          {transitions.map(({ item, props: { xy, ...rest }, key }) => (
            <animated.div
              key={item.title}
              style={{
                transform: xy.interpolate(
                  (x: any, y: any) => `translate3d(${x}px,${y}px,0)`
                ),
                ...rest,
              }}
              className="project-card"
            >
              <div
                className={'background-card'}
                style={{ backgroundImage: `url(https:${item.css})` }}
                onClick={() =>
                  navigate(
                    `${locale === 'it' ? '' : `/${locale}`}/${item.slug}/`
                  )
                }
              >
                <div className={'card-overlay'}>
                  <span>{item.title}</span>
                </div>
              </div>
            </animated.div>
          ))}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query($locale: String!) {
    allContentfulGallery(filter: { node_locale: { eq: $locale } }) {
      nodes {
        projectsList {
          title
          slug
          galleryImage {
            file {
              url
            }
          }
          thumbnailMobile {
            file {
              url
            }
          }
          galleryImageRatio
          category {
            categoryName
          }
        }
      }
    }
  }
`;

export default Gallery;
