import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { StylesProvider } from '@material-ui/core/styles';

export const CategoryFilters = ({ categories, media, setMedia }: any) => {
  const [activeCategory, setCategory] = useState('all');

  useEffect(() => {
    const el = document.querySelector('.list');
    el?.scrollIntoView();
  }, [activeCategory]);

  return typeof window !== 'undefined' && window.innerWidth < 800 ? (
    <StylesProvider injectFirst>
      <div className={'gallery-controls-container-mobile'}>
        <Select
          inputProps={{ color: 'white' }}
          value={activeCategory}
          onChange={(e) => {
            setCategory(e.target.value);
          }}
        >
          <MenuItem
            value={'all'}
            onClick={() => {
              // const body = document.querySelector('.list');
              // body?.scrollTo({ top: 0 });
              setMedia(
                // shuffle(
                media
                // )
              );
            }}
          >
            All
          </MenuItem>
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              onClick={() => {
                setMedia(
                  media.filter((el: any) => {
                    return category
                      ? el.category.some((x: any) => x === category)
                      : el;
                  })
                );
              }}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
      </div>
    </StylesProvider>
  ) : (
    // </div>
    <div className={'gallery-controls-container'}>
      <div>
        <span
          className={`category-filter ${
            activeCategory === 'all' && 'category-filter-active'
          }`}
          onClick={() => {
            // const body = document.querySelector('.list');
            // body?.scrollTo({ top: 0 });
            setMedia(
              // shuffle(
              media
              // )
            );
            setCategory('all');
          }}
        >
          All
        </span>
      </div>

      {categories.map((category: any, idx: any) => (
        <div
          key={`${idx}-label`}
          onClick={() => {
            // const body = document.querySelector('.list');
            // body?.scrollTo({ top: 0 });

            setMedia(
              media.filter((el: any) => {
                return category
                  ? el.category.some((x: any) => x === category)
                  : el;
              })
            );
            setCategory(category);
          }}
        >
          <span
            className={`category-filter ${
              activeCategory === category && 'category-filter-active'
            }`}
          >
            {category}
          </span>
        </div>
      ))}
    </div>
  );
};
